import React, {useState} from "react";
import logoimg from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleRight,
  faAngleUp
} from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';

const Sidebar = () => {
    //   const userdata = useSelector((store) => store.userReducer.userdata);
  const [showcategoriesdropdown, setshowcategoriesdropdown] = useState(false);
  const [showenquiresfropdown, setshowenquiresfropdown] = useState(false);
  const dispatch = useDispatch(); 
  let navigate = useNavigate();
  const logoutpressed = () => {
    dispatch({ type: "userdata", payload: {} })
        navigate('/admin/login');
  }
    return (
        <div className="sidebar-main webadmin-sidebarmain">
            <img src={logoimg} className="sidebarLogo" />
            <div className="sidebarMenuDiv">
            <ul>
          <li>
            <Link to={'/admin/cities'} className="webadmin-sidebar-maintitle">Cities</Link>
          </li>
          <li>
            <Link to={'/admin/hotels'} className="webadmin-sidebar-maintitle">Hotels</Link>
          </li>
          <li>
            <Link to={'/admin/rooms'} className="webadmin-sidebar-maintitle">Rooms</Link>
          </li>
          <li>
            <Link to={'/admin/enhance-stay'} className="webadmin-sidebar-maintitle">Enhance Stay</Link>
          </li>
          <li>
            <Link to={'/admin/bookings'} className="webadmin-sidebar-maintitle">Bookings</Link>
          </li>
          <li>
            <Link to={'/admin/promocodes'} className="webadmin-sidebar-maintitle">Promo Codes</Link>
          </li>


            {/* multi dropdown */}
          {/* <li>
            <div className="webadmin-sidebar-maintitlediv" onClick={() => {
            setshowcategoriesdropdown(!showcategoriesdropdown);
          }}>
            <a className="webadmin-sidebar-maintitle">Courses</a>
            {
              showcategoriesdropdown ?
              <FontAwesomeIcon icon={faAngleUp} className="sidebaricons" /> :
              <FontAwesomeIcon icon={faAngleDown} className="sidebaricons" />
            }
            
          </div>
            <div
              class="dropdown-container"
              style={
                showcategoriesdropdown
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <Link to="/webadmin/addnewcourse" className="webadmin-sidebar-subtitle">Add Course</Link> 
              <Link to="/webadmin/courselist" className="webadmin-sidebar-subtitle">Courses List</Link>
            </div>
          </li> */}

          
          
          <li>
          <div className="webadmin-sidebar-maintitlediv" onClick={() => {
           logoutpressed()
          }}>
            <a className="webadmin-sidebar-maintitle">Logout</a>
            
          </div>
          </li>
        </ul>
            </div>
        </div>
    )
}

export default Sidebar