import React, { useEffect, useState,useCallback,
    useRef,
    StrictMode, } from "react";
 import Sidebar from "../components/Sidebar";
  import { useSelector, useDispatch } from "react-redux";
  import { Link, useNavigate } from "react-router-dom";
  import axios from "axios";
  import { apiUrl, assetsUrl } from "../../config/Config";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { faPenToSquare, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";
  import { Button, Modal } from "react-bootstrap";
  import "bootstrap/dist/css/bootstrap.min.css";
  import Overlay from "../components/Overlay";
  import { useMemo } from 'react';
  import { AgGridReact } from "@ag-grid-community/react";
  import "@ag-grid-community/styles/ag-grid.css";
  import "@ag-grid-community/styles/ag-theme-quartz.css";
  import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
  import { ModuleRegistry } from "@ag-grid-community/core";
  import Select from 'react-select';
  import IconPicker from 'react-icons-picker';
  import Editor from "../components/Editor";
//   import * as Icons from 'react-icons';

  ModuleRegistry.registerModules([ClientSideRowModelModule]);
  
  const Enhances = () => {
    let navigate = useNavigate();
    const userdata = useSelector((store) => store.userReducer.userdata);
    const [showloding, setshowloding] = useState(false);
    const [showmodal, setshowmodal] = useState(false);
    const [editshowmodal, seteditshowmodal] = useState(false);
    const [selectedid, setselectedid] = useState(0);
    const [rowData, setRowData] = useState([]);
    const [colDefs, setColDefs] = useState([]);
    const [editorLoaded, setEditorLoaded] = useState(true);
    const [maxadults, setmaxadults] = useState(3);
    const [description, setdescription] = useState("");

    const [selectedicon, setselectedicon] = useState("");

    const [title, settitle] = useState("");
    const [enhanceimage, setenhanceimage] = useState("");
    const [price, setprice] = useState("");
    const [hotelsarr, sethotelsarr] = useState([]);
    const [selectedhotel, setselectedhotel] = useState("");

    const maxadultsRef = useRef(3);
    maxadultsRef.current = maxadults;


    const paginationPageSizeSelector = useMemo(() => {
      return [10, 20, 50, 100];
    }, []);

    const options = [
        { value: 'India', label: 'India' },
        { value: 'United States of America', label: 'United States of America' },
        { value: 'United Kingdom', label: 'United Kingdom' },
        { value: 'Canada', label: 'Canada' },
        { value: 'Australia', label: 'Australia' },
      ];
  
    const defaultColDef = {
      flex: 1,
    };
  
    const clickhandle = () =>  {
      console.log('Mission Launched')
    }
    
    const fetchallcourses = async () => {
      setshowloding(true);
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .get(apiUrl + "admingetenhance", headers)
        .then((res) => {
          if (res.data.status == "success") {
            console.log('admingetenhance >>', res.data);
            var prevdata = res.data.data;
            if(prevdata?.length > 0) {
              var columnsarr = [];
              var hidecolumns = ['id', 'updated_at','created_at', 'sort', 'country', 'address', 'promotext', 'description', 'city', 'hotelid', 'image', 'created', 'count'];
              var hidefilters = [];
              var columns = Object.keys(prevdata[0]);
              for(var i = 0; i < columns?.length;i++) {
                if(!hidecolumns.includes(columns[i])) {
                  var obj = {};
                  obj.field = columns[i];
                  if(!hidefilters.includes(columns[i])) {
                    obj.filter = true;
                    obj.filterParams = {
                        filterOptions: ["contains"],
                        maxNumConditions: 1,
                    };
                  }
                //   console.log('obj >>', obj)
                  columnsarr.push(obj);
                }
              }
              //Image
              if(columns.includes('image')) {
                var imageobj = 
            {
              field: "Image",
              headerName: "Image",
              cellRenderer: (props) => {
                // console.log('image props >>', props);
                return (
                  <Link
                  to={assetsUrl+'enhanceimages/'+props?.data?.image}
                  target="_blank"
                  style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
                >
                  <FontAwesomeIcon
                    icon={faEye}
                    className="sidebaricons"
                    style={{ color: "blue" }}
                  />
                </Link>
                )
              },
              cellRendererParams: {
                onClick: clickhandle,
              },
            }
            columnsarr.push(imageobj);
              }
              //edit
              var editobj = 
              {
                field: "edit",
                headerName: "Edit",
                cellRenderer: (props) => {
                  
                  return (
                    <div
                          onClick={() => {
                            editlocation(props?.data);
                            // console.log('props >>', props?.data);
                          }}
                          style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
                        >
                          <FontAwesomeIcon
                            icon={faPenToSquare}
                            className="sidebaricons"
                            style={{ color: "blue" }}
                          />
                        </div>
                  )
                },
                cellRendererParams: {
                  onClick: clickhandle,
                },
              }
              columnsarr.push(editobj);
              //delete
              var deleteobj = 
              {
                field: "delete",
                headerName: "Delete",
                cellRenderer: (props) => {
                  
                  return (
                    <div
                          onClick={() => {
                            deletecourse(props?.data?.id, props?.data?.title);
                            // console.log('props >>', props?.data);
                          }}
                          style={{maxWidth: 35, paddingLeft: 10, paddingRight: 10, cursor: 'pointer'}}
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            className="sidebaricons"
                            style={{ color: "red" }}
                          />
                        </div>
                  )
                },
                cellRendererParams: {
                  onClick: clickhandle,
                },
              }
              columnsarr.push(deleteobj);
              setColDefs(columnsarr);
              setRowData(prevdata);
            } else {
              setColDefs([]);
              setRowData([]);
            }
            
            // setcoursesdata(res.data.data);
          } else {
            // alert(res.data.message);
          }
        })
        .catch((err) => {
          alert(err.message);
        });
        setshowloding(false);
    };
    const deletecourse = async (id, name) => {
      const result = window.confirm("Are you sure you want to delete enhance " + name);
      if (result) {
        var data = JSON.stringify({
          id: id,
        });
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + userdata?.token,
          },
        };
        let resp = await axios
          .post(apiUrl + "deleteenhance", data, headers)
          .then((res) => {
            if (res.data.status == "success") {
              fetchallcourses();
            } else {
            }
          })
          .catch((err) => {
            alert(err.message);
          });
      } else {
        // User clicked Cancel or closed the dialog
        console.log("User clicked Cancel");
      }
    };
    const submithandle = async () => {
      if (title) {
        setshowloding(true);
        var data = {
            title: title,
            price: price,
            hotel: selectedhotel?.value,
            maxlimit: maxadultsRef.current,
            image: enhanceimage,
            description: description
        };

        console.log('data >>>', data);
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + userdata?.token,
          },
        };
        let resp = await axios
          .post(apiUrl + "createenhance", data, headers)
          .then((res) => {
            // console.log('res >>>', res.data)
            if (res.data.status == "success") {
              setshowmodal(false);
              setselectedid(0);
              settitle("");
              setprice("");
              setselectedhotel("");
              setmaxadults(3)
              setdescription("")
              setshowloding(false);
              fetchallcourses();
            } else {
              alert(res.data.message);
              setshowloding(false);
            }
          })
          .catch((err) => {
            setshowloding(false);
            alert(err.message);
          });
        setshowloding(false);
      } else {
        alert("Empty Field");
      }
    };
  
    const editlocation = async(data) => {
      console.log('data >>', data);
      setselectedid(data.id);
      settitle(data.title);
      setprice(data.price);
      setselectedhotel({ value: data.hotelid, label: data.hotel });
      setmaxadults(parseInt(data.maxlimit))
      setdescription(data.description ? data.description : "")
      
      seteditshowmodal(true);
    }
  
    const submithandle1 = async () => {
      if (title ) {
        setshowloding(true);
        var data = {
          id: selectedid,
          title: title,
            price: price,
            hotel: selectedhotel?.value,
            maxlimit: maxadultsRef.current,
            image: enhanceimage,
            description: description
      };
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + userdata?.token,
          },
        };
        let resp = await axios
          .post(apiUrl + "updateenhance", data, headers)
          .then((res) => {
            // console.log('res >>>', res.data)
            if (res.data.status == "success") {
                seteditshowmodal(false);
                setselectedid(0);
                settitle("");
                setprice("");
                setselectedhotel("");
                setmaxadults(3)
                setdescription("")
                fetchallcourses();
                setshowloding(false);
            } else {
              alert(res.data.message);
              setshowloding(false);
            }
          })
          .catch((err) => {
            setshowloding(false);
            alert(err.message);
          });
        setshowloding(false);
      } else {
        alert("Empty Field");
      }
    };

    const handlechange = (selectedOption) => {
        setselectedhotel(selectedOption)
    }

    const handleIconChange = (icon) => {
        console.log("Selected icon: ", icon);
        setselectedicon(icon);
      };

    console.log('selectedicon >>', selectedicon)

    const fetchallcities = async () => {
      setshowloding(true);
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .get(apiUrl + "admingethotels", headers)
        .then((res) => {
          if (res.data.status == "success") {
            var data = res.data.data;
            console.log('data >>>', data)
            var newarr = [];
            if(data?.length > 0) {
              for(var i = 0; i < data?.length; i++) {
                var obj = {};
                obj['value'] = data[i].id;
                obj['label'] = data[i].name;
                newarr.push(obj);
              }
              console.log('newarr1 >>', newarr);
              sethotelsarr(newarr);
            }
            // setcoursesdata(res.data.data);
          } else {
            // alert(res.data.message);
          }
        })
        .catch((err) => {
          alert(err.message);
        });
        setshowloding(false);
    };

    const handleplusadults = () => {
      var prevdata = maxadultsRef.current;
      setmaxadults(prevdata + 1);
    }

    const handleminusadults = () => {
      var prevdata = maxadultsRef.current;
      if(prevdata == 1) {

      } else {
        setmaxadults(prevdata - 1);
      }
      
    }


    const getBase64 = (file) => {
        return new Promise((resolve) => {
          let fileInfo;
          let baseURL = "";
          // Make new FileReader
          let reader = new FileReader();
    
          // Convert the file to base64 text
          reader.readAsDataURL(file);
    
          // on reader load somthing...
          reader.onload = () => {
            // Make a fileInfo Object
            // console.log("Called", reader);
            baseURL = reader.result;
            // console.log(baseURL);
            resolve(baseURL);
          };
          // console.log(fileInfo);
        });
      };
    
      const handleinputchange = async (file) => {
        var obj = {};
        await getBase64(file).then((result) => {
          obj["name"] = file.name;
          obj["image"] = result;
        });
        // console.log('obj >>', obj);
        setenhanceimage(obj);
      };

  
   
    useEffect(() => {
      if (userdata && Object.keys(userdata).length > 0) {
        //   fetchdata();
        fetchallcourses();
        fetchallcities();
      } else {
        navigate("/newadminlogin");
      }
    }, []);
    return (
      <div className="HomeMain">
        {/* <Overlay showloader={showloding} /> */}
        <div className="sidebar-div">
          <Sidebar name={"Admin"} />
        </div>
        <div className="main-layout">
          <div className="mainDiv">
            <p className="oneplaceHead">ENHANCE STAY LIST</p>
            <br />
            <div className="tablebuttondiv tablebuttondivpage">
              <Link
                onClick={() => {
                  setshowmodal(true);
                  setselectedid(0);
              settitle("");
              setprice("");
              setselectedhotel("");
              setmaxadults(3)
              setdescription("")
                }}
                className="btndiv webadminmaindiv"
              >
                <p>{"Add New Enhance"}</p>
              </Link>
            </div>

            <div
              className={"ag-theme-quartz"}
              style={{ width: "100%", height: 550, marginBottom: 30 }}
            >
              <AgGridReact
                // modules={AllModules}
                rowData={rowData}
                columnDefs={colDefs}
                // defaultColDef={defaultColDef}
                suppressRowClickSelection={true}
                groupSelectsChildren={true}
                rowSelection={"multiple"}
                pagination={true}
                paginationPageSize={10}
                paginationPageSizeSelector={paginationPageSizeSelector}
                // onGridReady={onGridReady}
              />
            </div>

            {/*Add modal */}
            <Modal
              show={showmodal}
              onHide={() => setshowmodal(false)}
              size="md"
              className="custom-width-modal"
            >
              <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: 19 }}>Add Enhance Stay</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="webadmin-modalmainbody">
                  <div className="">
                    <label>Title</label>
                    <input
                      value={title}
                      onChange={(t) => {
                        settitle(t.target.value);
                      }}
                      type="text"
                      className="form-control tabinput"
                      placeholder="Enter Title of Enhance"
                    />
                  </div>
                  <div className="col-md-12 mt-3">
                      <label>Price</label>
                      <input
                      value={price}
                      onChange={(t) => {
                        setprice(t.target.value);
                      }}
                      type="number"
                      className="form-control tabinput"
                      placeholder="Enter Price"
                    />
                    </div>
                    <div className="mt-3">
                    <label className="admin-label">Enhance Image</label>
                    <input
                    onChange={(t) => {
                      handleinputchange(t.target.files[0]);
                    }}
                      accept="image/*"
                      type="file"
                      className="form-control admininput"
                      placeholder="Enhance Image"
                    />
                  </div>
                    <div className="col-md-12 mt-3">
                    <label>Hotel</label>
                      <Select value={selectedhotel} onChange={handlechange} options={hotelsarr} />
                    </div>
                    <div className="col-md-12 mt-3">
                      <div className="adultsmaindiv">
                        <label>Max Limit</label>
                        <div className="adultsboxdiv">
                          <div
                            className="btn plusbox"
                            onClick={() => {
                                handleplusadults();
                            }}
                          >
                            +
                          </div>
                          <div className="adultinput">
                            <p>{maxadultsRef.current}</p>
                          </div>
                          <div
                            className="btn plusbox"
                            onClick={() => {
                                handleminusadults();
                            }}
                          >
                            -
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-3">
                    <label>Description</label>
                    <Editor
                      name="description"
                      onChange={(t) => {
                        setdescription(t);
                      }}
                      editorLoaded={editorLoaded}
                    />
                    </div>
                  <div
                    className="webadmin-modalbtn"
                    onClick={() => {
                      submithandle();
                    }}
                  >
                    <p>Submit</p>
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            {/*Edit modal */}
            <Modal
              show={editshowmodal}
              onHide={() => seteditshowmodal(false)}
              size="md"
              className="custom-width-modal"
            >
              <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: 19 }}>Edit Enhance Stay</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <div className="webadmin-modalmainbody">
                  <div className="">
                    <label>Title</label>
                    <input
                      value={title}
                      onChange={(t) => {
                        settitle(t.target.value);
                      }}
                      type="text"
                      className="form-control tabinput"
                      placeholder="Enter Title of Enhance"
                    />
                  </div>
                  <div className="col-md-12 mt-3">
                      <label>Price</label>
                      <input
                      value={price}
                      onChange={(t) => {
                        setprice(t.target.value);
                      }}
                      type="number"
                      className="form-control tabinput"
                      placeholder="Enter Price"
                    />
                    </div>
                    <div className="mt-3">
                    <label className="admin-label">Enhance Image</label>
                    <input
                    onChange={(t) => {
                      handleinputchange(t.target.files[0]);
                    }}
                      accept="image/*"
                      type="file"
                      className="form-control admininput"
                      placeholder="Enhance Image"
                    />
                  </div>
                    <div className="col-md-12 mt-3">
                    <label>Hotel</label>
                      <Select value={selectedhotel} onChange={handlechange} options={hotelsarr} />
                    </div>
                    <div className="col-md-12 mt-3">
                      <div className="adultsmaindiv">
                        <label>Max Limit</label>
                        <div className="adultsboxdiv">
                          <div
                            className="btn plusbox"
                            onClick={() => {
                                handleplusadults();
                            }}
                          >
                            +
                          </div>
                          <div className="adultinput">
                            <p>{maxadultsRef.current}</p>
                          </div>
                          <div
                            className="btn plusbox"
                            onClick={() => {
                                handleminusadults();
                            }}
                          >
                            -
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-3">
                    <label>Description</label>
                    <Editor
                      name="description"
                      value={description}
                      onChange={(t) => {
                        setdescription(t);
                      }}
                      editorLoaded={editorLoaded}
                    />
                    </div>
                  <div
                    className="webadmin-modalbtn"
                    onClick={() => {
                      submithandle1();
                    }}
                  >
                    <p>Submit</p>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    );
  };
  
  export default Enhances;
  