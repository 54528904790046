import React, {
    useEffect,
    useState,
    useCallback,
    useRef,
    StrictMode,
  } from "react";
  import Sidebar from "../components/Sidebar";
  import { useSelector, useDispatch } from "react-redux";
  import { Link, useNavigate } from "react-router-dom";
  import axios from "axios";
  import { apiUrl, assetsUrl } from "../../config/Config";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { faPenToSquare, faTrash, faPlus, faMinus, faXmark } from "@fortawesome/free-solid-svg-icons";
  import { Button, Modal } from "react-bootstrap";
  import "bootstrap/dist/css/bootstrap.min.css";
  import Overlay from "../components/Overlay";
  import { useMemo } from "react";
  import { AgGridReact } from "@ag-grid-community/react";
  import "@ag-grid-community/styles/ag-grid.css";
  import "@ag-grid-community/styles/ag-theme-quartz.css";
  import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
  import { ModuleRegistry } from "@ag-grid-community/core";
  import Select from "react-select";
  import IconPicker from "react-icons-picker";
  import Editor from "../components/Editor";
  import Spinner from "react-activity/dist/Spinner";
  import "react-activity/dist/Spinner.css"; 
  //   import * as Icons from 'react-icons';
  
  ModuleRegistry.registerModules([ClientSideRowModelModule]);
  
  const Bookings = () => {
    let navigate = useNavigate();
    const userdata = useSelector((store) => store.userReducer.userdata);
    const [showloding, setshowloding] = useState(false);
    const [showviewmodal, setshowviewmodal] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [colDefs, setColDefs] = useState([]);
    const [bookingdetails, setbookingdetails] = useState({});

    const [showdetailsloader, setshowdetailsloader] = useState(false);
  
    const paginationPageSizeSelector = useMemo(() => {
      return [10, 20, 50, 100];
    }, []);
  
    const clickhandle = () => {
      console.log("Mission Launched");
    };
  
    const fetchallcourses = async () => {
      setshowloding(true);
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .get(apiUrl + "admingetbookings", headers)
        .then((res) => {
          if (res.data.status == "success") {
            console.log("admingetbookings >>", res.data);
            var prevdata = res.data.data;
            if (prevdata?.length > 0) {
              var columnsarr = [];
              var hidecolumns = [
                "id",
                "updated_at",
                "created_at",
                "sort",
                "country",
                "address",
                "promotext",
                "description",
                "city",
                "images",
                "amenitiesdescription"
              ];
              var hidefilters = [];
              var columns = Object.keys(prevdata[0]);
              for (var i = 0; i < columns?.length; i++) {
                if (!hidecolumns.includes(columns[i])) {
                  var obj = {};
                  obj.field = columns[i];
                  if (!hidefilters.includes(columns[i])) {
                    obj.filter = true;
                    obj.filterParams = {
                      filterOptions: ["contains"],
                      maxNumConditions: 1,
                    };
                  }
                  //   console.log('obj >>', obj)
                  columnsarr.push(obj);
                }
              }
              //edit
              var editobj = {
                field: "edit",
                headerName: "Edit / View",
                cellRenderer: (props) => {
                  return (
                    <div
                      onClick={() => {
                        editlocation(props?.data);
                      }}
                      style={{
                        maxWidth: 35,
                        paddingLeft: 10,
                        paddingRight: 10,
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faPenToSquare}
                        className="sidebaricons"
                        style={{ color: "blue" }}
                      />
                    </div>
                  );
                },
                cellRendererParams: {
                  onClick: clickhandle,
                },
              };
              columnsarr.push(editobj);
              

              setColDefs(columnsarr);
              setRowData(prevdata);
            }
  
            // setcoursesdata(res.data.data);
          } else {
            // alert(res.data.message);
          }
        })
        .catch((err) => {
          alert(err.message);
        });
      setshowloding(false);
    };

    console.log('booking details >>', bookingdetails);

    const dateDifference = (date1, date2) => {
      // Parse the date strings into Date objects
      const startDate = new Date(date1);
      const endDate = new Date(date2);
    
      // Calculate the difference in milliseconds
      const diffInMs = endDate - startDate;
    
      // Convert the difference from milliseconds to days
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    
      // Return the difference in days
      return Math.round(diffInDays);
    }

    const fetchbookingdetails = async(id) => {
      setshowdetailsloader(true);
      var data = {
        id: id
      }
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .post(apiUrl + "admingetbookingdetails",data, headers)
        .then((res) => {
          if (res.data.status == "success") {
            console.log("admingetbookings >>", res.data);
            var prevdata = res.data.data;
            if(prevdata?.length > 0) {
              setbookingdetails(prevdata[0]);
            }
          } else {
          }
          setshowdetailsloader(false);
        })
        .catch((err) => {
          alert(err.message);
          setshowdetailsloader(false);
        });
        setshowdetailsloader(false);
    }
  
    const editlocation = async (data) => {
      setbookingdetails({});
      fetchbookingdetails(data?.id)
      console.log("data >>", data);
      setshowviewmodal(true)
    };

    const dateFormatConvert = (dateString) => {
      // Create a Date object from the input string
      const date = new Date(dateString);
  
      // Define an array of month names
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
      // Define an array of day names
      const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  
      // Extract day, month, year, and day of the week
      const day = date.getDate();
      const month = monthNames[date.getMonth()];
      const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year
      const dayOfWeek = dayNames[date.getDay()];
  
      // Return the formatted date string
      return `${day} ${month} '${year}, ${dayOfWeek}`;
  }
  
   
  
  
    useEffect(() => {
      if (userdata && Object.keys(userdata).length > 0) {
        //   fetchdata();
        fetchallcourses();
      } else {
        navigate("/newadminlogin");
      }
    }, []);
    return (
      <div className="HomeMain">
        {/* <Overlay showloader={showloding} /> */}
        <div className="sidebar-div">
          <Sidebar name={"Admin"} />
        </div>
        <div className="main-layout">
          <div className="mainDiv">
            <p className="oneplaceHead">BOOKINGS LIST</p>
            <br />

            <div
              className={"ag-theme-quartz"}
              style={{ width: "100%", height: 550, marginBottom: 30 }}
            >
              <AgGridReact
                // modules={AllModules}
                rowData={rowData}
                columnDefs={colDefs}
                // defaultColDef={defaultColDef}
                suppressRowClickSelection={true}
                groupSelectsChildren={true}
                rowSelection={"multiple"}
                pagination={true}
                paginationPageSize={10}
                paginationPageSizeSelector={paginationPageSizeSelector}
                // onGridReady={onGridReady}
              />
            </div>

            {/*View modal */}
            <Modal
              show={showviewmodal}
              onHide={() => setshowviewmodal(false)}
              size="xl"
              className="custom-width-modal"
            >
              <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: 19 }}>
                  Booking Details
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="webadmin-modalmainbody">
                  {
                    Object.keys(bookingdetails).length > 0 ?
                    <div className="row">
                    <div className="col-md-4">
                      <div className="viewbookingbox">
                        <p className="viewboxhead">Total Nights: {dateDifference(bookingdetails?.startdate, bookingdetails?.enddate)}</p>
                        <div className="checkinrow">
                          <div className="checkinbox">
                            <p className="checkintext">Check In</p>
                            <p className="checkindate">{dateFormatConvert(bookingdetails?.startdate)}</p>
                          </div>
                          <div className="checkoutbox">
                            <p className="checkintext">Check Out</p>
                            <p className="checkindate">{dateFormatConvert(bookingdetails?.enddate)}</p>
                          </div>
                        </div>
                      </div>
                      <div className="viewbookingbox mt-4">
                        <p className="viewboxhead">User Details</p>
                        <div className="viewbookinguserdiv">
                          <p className="checkintext">Name</p>
                          <p className="checkintext checkintextvalue">
                           {bookingdetails?.name}
                          </p>
                        </div>
                        <div className="viewbookinguserdiv">
                          <p className="checkintext">Email</p>
                          <p className="checkintext checkintextvalue">
                          {bookingdetails?.email}
                          </p>
                        </div>
                        <div className="viewbookinguserdiv">
                          <p className="checkintext">Number</p>
                          <p className="checkintext checkintextvalue">
                          {bookingdetails?.number}
                          </p>
                        </div>
                        <div className="viewbookinguserdiv">
                          <p className="checkintext">Gst Number</p>
                          <p className="checkintext checkintextvalue">
                          {bookingdetails?.gstnumber}
                          </p>
                        </div>
                        <div className="viewbookinguserdiv">
                          <p className="checkintext">special Request</p>
                          <p className="checkintext checkintextvalue">
                          {bookingdetails?.special_request}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="viewbookingbox">
                        <p className="viewboxhead">Rooms Booked</p>
                        {
                          bookingdetails?.booking_rooms?.length > 0 ?
                          bookingdetails?.booking_rooms?.map((item, index) => {
                            return (
                              <div className="viewroomsbookeddiv">
                                <div className="viewbookinguserdiv">
                                  <p className="checkintext">Room {index + 1}</p>
                                  <p className="checkintext checkintextvalue">
                                    {item?.title} ({item?.variant})
                                  </p>
                                </div>
                                <div className="viewbookinguserdiv">
                                  <p className="checkintext">Guests</p>
                                  <p className="checkintext checkintextvalue">
                                    {item?.adults} Adult, {item?.children} Children
                                  </p>
                                </div>
                              </div>
                            );
                          }) : null
                        }
                        
                      </div>
                      <div className="viewbookingbox mt-4">
                        <p className="viewboxhead">Enhance Your Stay</p>
                        {
                          bookingdetails?.enhancement ?
                          JSON.parse( bookingdetails?.enhancement )?.map((item, index) => {
                            return (
                              <div className="viewbookinguserdiv" key={index}>
                                <p className="checkintext">{item?.title}</p>
                                <p className="checkintext checkintextvalue">
                                {item?.count} Unit
                                </p>
                              </div>
                            );
                          }) : null
                        }
                          
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="">
                        <select className="form-control" style={{height: 42}}>
                          <option disabled selected>Select Payment Status</option>
                          <option>Not Paid</option>
                          <option>Partially Paid</option>
                          <option>Fully Paid</option>
                        </select>
                        <div class="booknowbtn booknowbtn1 booknowadminpaymentbtn"><p>Update</p></div>
                      </div>
                      <div className="viewbookingbox viewbookingoxpayment mt-4">
                        <p className="viewboxhead">Payment Details</p>
                        <div className="viewbookinguserdiv">
                            <p className="checkintext">Sub Total</p>
                            <p className="checkintext checkintextvalue">
                            INR {bookingdetails?.subtotal}
                            </p>
                          </div>
                          <div className="viewbookinguserdiv">
                            <p className="checkintext">Taxes</p>
                            <p className="checkintext checkintextvalue">
                            INR {bookingdetails?.tax}
                            </p>
                          </div>
                          <hr />
                          <div className="viewbookinguserdiv">
                            <p className="checkintext">Grand Total</p>
                            <p className="checkintext checkintextvalue">
                            <b>INR {bookingdetails?.totalamount}</b>
                            </p>
                          </div>
                          <div className="viewbookinguserdiv">
                            <p className="checkintext">Adv Payment</p>
                            <p className="checkintext checkintextvalue">
                            INR {parseInt(bookingdetails?.paidamount).toFixed(2)}
                            </p>
                          </div>
                          <div className="viewbookinguserdiv">
                            <p className="checkintext">Bal Payment</p>
                            <p className="checkintext checkintextvalue">
                            INR {(parseInt(bookingdetails?.totalamount) - parseInt(bookingdetails?.paidamount)).toFixed(2)}
                            </p>
                          </div>
                      </div>
                    </div>
                  </div> : 
                  <div className="">
                    {
                      showdetailsloader ?
                      <Spinner color='#000' size={24} className='spinnerCenter' /> :
                      <p>No data to show</p>
                    }
                   
                  </div>
                  
                  }
                  
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    );
  };
  
  export default Bookings;
  