import React from "react";
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from "../pages/Home";
import Login from "../admin/pages/Login";
import AdminHome from "../admin/pages/Home";
import Cities from "../admin/pages/Cities";
import Hotels from "../admin/pages/Hotels";
import PromoCodes from "../admin/pages/PromoCodes";
import Enhances from "../admin/pages/Enhances";
import Rooms from "../admin/pages/Rooms";
import Bookings from "../admin/pages/Bookings";

const AllRoutes = () => {
    return (
        <Router basename={'/'} >
             <Routes>
                <Route exact path="/" element={<Home />} />

                <Route exact path="/admin" element={<Login />} />
                <Route exact path="/admin/login" element={<Login />} />
                <Route exact path="/admin/home" element={<Bookings />} />
                <Route exact path="/admin/cities" element={<Cities />} />
                <Route exact path="/admin/hotels" element={<Hotels />} />
                <Route exact path="/admin/promocodes" element={<PromoCodes />} />
                <Route exact path="/admin/enhance-stay" element={<Enhances />} />
                <Route exact path="/admin/rooms" element={<Rooms />} />
                <Route exact path="/admin/bookings" element={<Bookings />} />
             </Routes>
        </Router>
    )
}

export default AllRoutes