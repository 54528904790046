import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  StrictMode,
} from "react";
import Sidebar from "../components/Sidebar";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { apiUrl, assetsUrl } from "../../config/Config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash, faPlus, faMinus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Overlay from "../components/Overlay";
import { useMemo } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import Select from "react-select";
import IconPicker from "react-icons-picker";
import Editor from "../components/Editor";
//   import * as Icons from 'react-icons';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const Rooms = () => {
  let navigate = useNavigate();
  const userdata = useSelector((store) => store.userReducer.userdata);
  const [showloding, setshowloding] = useState(false);
  const [showmodal, setshowmodal] = useState(false);
  const [editshowmodal, seteditshowmodal] = useState(false);
  const [selectedid, setselectedid] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [colDefs, setColDefs] = useState([]);
  const [editorLoaded, setEditorLoaded] = useState(true);

  const [citysarr, setcitysarr] = useState([]);
  const [hotelname, sethotelname] = useState("");
  const [address, setaddress] = useState("");
  const [selectedcity, setselectedcity] = useState(null);
  const [maxadults, setmaxadults] = useState(3);
  const [maxchildrens, setmaxchildrens] = useState(2);
  const [promotext, setpromotext] = useState("");
  const [description, setdescription] = useState("");
  const [taxes, settaxes] = useState("");
  const [maxrooms, setmaxrooms] = useState(1);

const roomsobj = {
    price: "",
    offerprice: "",
    images: [],
    ratetitle: "",
    ratedesc: "",
    amenitiesdesc: ""
}
  const [forceupdatestate, setforceupdatestate] = useState(false);
  const [roomsdescription, setroomsdescription] = useState([roomsobj]);
  const [hotelsarr,sethotelsarr] = useState([]);
  const [selectedhotel, setselectedhotel] = useState("");
  const [roomtitle, setroomtitle] = useState("");
  const [roomtax, setroomtax] = useState("");
  const [roomsize, setroomsize] = useState("");
  const [imagesarr, setimagesarr] = useState([]);
  const [amenitiesdesc, setamenitiesdesc] = useState("")
  

  const roomsdescriptionRef = useRef(null);
  roomsdescriptionRef.current = roomsdescription;

  const [selectedicon, setselectedicon] = useState("");

  const maxadultsRef = useRef(3);
  maxadultsRef.current = maxadults;

  const maxchildrensRef = useRef(2);
  maxchildrensRef.current = maxchildrens;

  const maxroomsRef = useRef(1);
  maxroomsRef.current = maxrooms;

  const paginationPageSizeSelector = useMemo(() => {
    return [10, 20, 50, 100];
  }, []);

  const options = [
    { value: "India", label: "India" },
    { value: "United States of America", label: "United States of America" },
    { value: "United Kingdom", label: "United Kingdom" },
    { value: "Canada", label: "Canada" },
    { value: "Australia", label: "Australia" },
  ];

  const defaultColDef = {
    flex: 1,
  };

  const clickhandle = () => {
    console.log("Mission Launched");
  };

  const fetchallcourses = async () => {
    setshowloding(true);
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userdata?.token,
      },
    };
    let resp = await axios
      .get(apiUrl + "admingetrooms", headers)
      .then((res) => {
        if (res.data.status == "success") {
          console.log("admingetrooms >>", res.data);
          var prevdata = res.data.data;
          if (prevdata?.length > 0) {
            var columnsarr = [];
            var hidecolumns = [
              "id",
              "updated_at",
              "created_at",
              "sort",
              "country",
              "address",
              "promotext",
              "description",
              "city",
              "images",
              "amenitiesdescription"
            ];
            var hidefilters = [];
            var columns = Object.keys(prevdata[0]);
            for (var i = 0; i < columns?.length; i++) {
              if (!hidecolumns.includes(columns[i])) {
                var obj = {};
                obj.field = columns[i];
                if (!hidefilters.includes(columns[i])) {
                  obj.filter = true;
                  obj.filterParams = {
                    filterOptions: ["contains"],
                    maxNumConditions: 1,
                  };
                }
                //   console.log('obj >>', obj)
                columnsarr.push(obj);
              }
            }
            //edit
            var editobj = {
              field: "edit",
              headerName: "Edit",
              cellRenderer: (props) => {
                return (
                  <div
                    onClick={() => {
                      editlocation(props?.data);
                      // console.log('props >>', props?.data);
                    }}
                    style={{
                      maxWidth: 35,
                      paddingLeft: 10,
                      paddingRight: 10,
                      cursor: "pointer",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPenToSquare}
                      className="sidebaricons"
                      style={{ color: "blue" }}
                    />
                  </div>
                );
              },
              cellRendererParams: {
                onClick: clickhandle,
              },
            };
            columnsarr.push(editobj);
            //delete
            var deleteobj = {
              field: "delete",
              headerName: "Delete",
              cellRenderer: (props) => {
                return (
                  <div
                    onClick={() => {
                      deletecourse(props?.data?.id, props?.data?.title,  props?.data?.hotel);
                      // console.log('props >>', props?.data);
                    }}
                    style={{
                      maxWidth: 35,
                      paddingLeft: 10,
                      paddingRight: 10,
                      cursor: "pointer",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="sidebaricons"
                      style={{ color: "red" }}
                    />
                  </div>
                );
              },
              cellRendererParams: {
                onClick: clickhandle,
              },
            };
            columnsarr.push(deleteobj);
            setColDefs(columnsarr);
            setRowData(prevdata);
          }

          // setcoursesdata(res.data.data);
        } else {
          // alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
    setshowloding(false);
  };
  const deletecourse = async (id, name, hotel) => {
    const result = window.confirm(
      "Are you sure you want to delete room " + name + " from hotel " + hotel
    );
    if (result) {
      var data = JSON.stringify({
        id: id,
      });
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .post(apiUrl + "deleteroom", data, headers)
        .then((res) => {
          if (res.data.status == "success") {
            fetchallcourses();
          } else {
          }
        })
        .catch((err) => {
          alert(err.message);
        });
    } else {
      // User clicked Cancel or closed the dialog
      console.log("User clicked Cancel");
    }
  };
  const submithandle = async () => {
    if (roomtitle) {
      setshowloding(true);
      var data = {
        roomtitle: roomtitle,
        tax: roomtax,
        selectedhotel: selectedhotel?.value,
        maxadults: maxadultsRef.current,
        maxchildrens: maxchildrensRef.current,
        maxrooms: maxroomsRef.current,
        size: roomsize,
        description: roomsdescriptionRef.current,
        amenitiesdescription: amenitiesdesc,
        images: imagesarr
      };

      console.log("data >>>", data);
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .post(apiUrl + "createroom", data, headers)
        .then((res) => {
          // console.log('res >>>', res.data)
          if (res.data.status == "success") {
            setshowmodal(false);
            setselectedid(0);
            setroomtitle("");
            setselectedhotel("")
            setroomtax("");
            setmaxadults(3);
            setmaxchildrens(2);
            setmaxrooms(1);
            setroomsize("");
            setroomsdescription([roomsobj]);
            setamenitiesdesc("")
            setimagesarr([]);
            setshowloding(false);
            fetchallcourses();
          } else {
            alert(res.data.message);
            setshowloding(false);
          }
        })
        .catch((err) => {
          setshowloding(false);
          alert(err.message);
        });
      setshowloding(false);
    } else {
      alert("Empty Field");
    }
  };

  const editlocation = async (data) => {
    // console.log("data >>", data);
    setselectedid(data.id);
    setroomtitle(data.title);
    setselectedhotel({ value: data.hotelid, label: data.hotel })
    setroomtax(data.tax);
    setmaxadults(data.maxadults);
    setmaxchildrens(data.maxchildrens);
    setmaxrooms(data.maxrooms);
    setroomsize(data.roomsize);
    setroomsdescription(data.description ? JSON.parse(data.description) : roomsobj);
    setamenitiesdesc(data.amenitiesdescription);
    setimagesarr(data.images ? JSON.parse(data.images) : [])

    seteditshowmodal(true);
  };

  const submithandle1 = async () => {
    if (roomtitle) {
      setshowloding(true);
      var data = {
        id: selectedid,
        roomtitle: roomtitle,
        tax: roomtax,
        selectedhotel: selectedhotel?.value,
        maxadults: maxadultsRef.current,
        maxchildrens: maxchildrensRef.current,
        maxrooms: maxroomsRef.current,
        size: roomsize,
        description: roomsdescriptionRef.current,
        amenitiesdescription: amenitiesdesc,
        images: imagesarr
      };
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userdata?.token,
        },
      };
      let resp = await axios
        .post(apiUrl + "updateroom", data, headers)
        .then((res) => {
          // console.log('res >>>', res.data)
          if (res.data.status == "success") {
            seteditshowmodal(false);
            setselectedid(0);
            setroomtitle("");
            setselectedhotel("")
            setroomtax("");
            setmaxadults(3);
            setmaxchildrens(2);
            setmaxrooms(1);
            setroomsize("");
            setroomsdescription([roomsobj]);
            setamenitiesdesc("")
            setimagesarr([]);
            fetchallcourses();
            setshowloding(false);
          } else {
            alert(res.data.message);
            setshowloding(false);
          }
        })
        .catch((err) => {
          setshowloding(false);
          alert(err.message);
        });
      setshowloding(false);
    } else {
      alert("Empty Field");
    }
  };

  const handlechange = (selectedOption) => {
    setselectedhotel(selectedOption);
  };

  const handleIconChange = (icon) => {
    console.log("Selected icon: ", icon);
    setselectedicon(icon);
  };

  console.log("selectedicon >>", selectedicon);

  const fetchallcities = async () => {
    setshowloding(true);
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userdata?.token,
      },
    };
    let resp = await axios
      .get(apiUrl + "admingethotels", headers)
      .then((res) => {
        if (res.data.status == "success") {
          var data = res.data.data;
          console.log("data >>>", data);
          var newarr = [];
          if (data?.length > 0) {
            for (var i = 0; i < data?.length; i++) {
              var obj = {};
              obj["value"] = data[i].id;
              obj["label"] = data[i].name;
              newarr.push(obj);
            }
            console.log("newarr >>", newarr);
            sethotelsarr(newarr);
          }
          // setcoursesdata(res.data.data);
        } else {
          // alert(res.data.message);
        }
      })
      .catch((err) => {
        alert(err.message);
      });
    setshowloding(false);
  };

  const handleplusadults = () => {
    var prevdata = parseInt(maxadultsRef.current);
    setmaxadults(prevdata + 1);
  };

  const handleminusadults = () => {
    var prevdata = parseInt(maxadultsRef.current);
    if (prevdata == 1) {
    } else {
      setmaxadults(prevdata - 1);
    }
  };

  const handlepluschildrens = () => {
    var prevdata = parseInt(maxchildrensRef.current);
    setmaxchildrens(prevdata + 1);
  };

  const handleminuschildrens = () => {
    var prevdata = parseInt(maxchildrensRef.current);
    if (prevdata == 0) {
    } else {
      setmaxchildrens(prevdata - 1);
    }
  };

  const handleplusrooms = () => {
    var prevdata = maxroomsRef.current;
    setmaxrooms(prevdata + 1);
  };

  const handleminusrooms = () => {
    var prevdata = maxroomsRef.current;
    if (prevdata == 1) {
    } else {
      setmaxrooms(prevdata - 1);
    }
  };

  const handleplusratesdiv = () => {
    var prevdata = roomsdescriptionRef.current;
    prevdata.push(roomsobj);
    setroomsdescription(prevdata);
    setforceupdatestate(!forceupdatestate);
  }

  const handleminusratesdiv = () => {
    var prevdata = roomsdescriptionRef.current;
    if(prevdata?.length == 1) {

    } else {
        prevdata.pop();
    setroomsdescription(prevdata);
    setforceupdatestate(!forceupdatestate);
    }
    
  }

  const getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        // console.log("Called", reader);
        baseURL = reader.result;
        // console.log(baseURL);
        resolve(baseURL);
      };
      // console.log(fileInfo);
    });
  };

  const multipleimagesuploadfunc = async(files) => {
    // setdocumentsdssarr([]);
    console.log('files .>', files);
    // var prevdata = roomsdescriptionRef.current;
    // var oldimagesarr = prevdata[indx]['images'];
    var oldimagesarr = imagesarr;
    var documentsarr1 = [];
    for(var i = 0; i < files.length; i++) {
        var obj = {};
        await getBase64(files[i]).then(result => {
            obj['name'] = files[i].name;
            obj['image'] = result;
        });
        oldimagesarr.push(obj);
    }
    // handleinputchangeratesdess(indx, 'images', oldimagesarr)
    setimagesarr(oldimagesarr);
    
}

const handleinputchangeratesdess = (index, name, value) => {
    var prevdata = roomsdescriptionRef.current;
    prevdata[index][name] = value;
    setroomsdescription(prevdata);
    setforceupdatestate(!forceupdatestate);
}

const removeimagefunc = (sindex) => {
  var prevdata = imagesarr;
  prevdata.splice(sindex, 1);
  console.log('prevdata >>>', prevdata);
  setimagesarr(prevdata);
  setforceupdatestate(!forceupdatestate);
}

  useEffect(() => {
    if (userdata && Object.keys(userdata).length > 0) {
      //   fetchdata();
      fetchallcourses();
      fetchallcities();
    } else {
      navigate("/newadminlogin");
    }
  }, []);
  return (
    <div className="HomeMain">
      {/* <Overlay showloader={showloding} /> */}
      <div className="sidebar-div">
        <Sidebar name={"Admin"} />
      </div>
      <div className="main-layout">
        <div className="mainDiv">
          <p className="oneplaceHead">ROOMS LIST</p>
          <br />
          <div className="tablebuttondiv tablebuttondivpage">
            <Link
              onClick={() => {
                setselectedid(0);
                setroomtitle("");
                setselectedhotel("");
                setroomtax("");
                setmaxadults(3);
                setmaxchildrens(2);
                setmaxrooms(1);
                setroomsize("");
                setroomsdescription([roomsobj]);
                setamenitiesdesc("")
                setimagesarr([]);
                setshowmodal(true);
              }}
              className="btndiv webadminmaindiv"
            >
              <p>{"Add Room"}</p>
            </Link>
          </div>

          <div
            className={"ag-theme-quartz"}
            style={{ width: "100%", height: 550, marginBottom: 30 }}
          >
            <AgGridReact
              // modules={AllModules}
              rowData={rowData}
              columnDefs={colDefs}
              // defaultColDef={defaultColDef}
              suppressRowClickSelection={true}
              groupSelectsChildren={true}
              rowSelection={"multiple"}
              pagination={true}
              paginationPageSize={10}
              paginationPageSizeSelector={paginationPageSizeSelector}
              // onGridReady={onGridReady}
            />
          </div>

          {/*Add modal */}
          <Modal
            show={showmodal}
            onHide={() => setshowmodal(false)}
            size="lg"
            className="custom-width-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title style={{ fontSize: 19 }}>Add Room</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="webadmin-modalmainbody">
                <div className="row">
                  <div className="col-md-5">
                    <div className="">
                      <label>Room Title</label>
                      <input
                        value={roomtitle}
                        onChange={(t) => {
                          setroomtitle(t.target.value);
                        }}
                        type="text"
                        className="form-control tabinput"
                        placeholder="Enter Title of room"
                      />
                    </div>
                  </div>
                  <div className="col-md-5">
                    <label>Select Hotel</label>
                    <Select
                      value={selectedhotel}
                      onChange={handlechange}
                      options={hotelsarr}
                    />
                  </div>
                  <div className="col-md-2">
                    <div className="">
                      <label>Tax(in %)</label>
                      <input
                        value={roomtax}
                        onChange={(t) => {
                          setroomtax(t.target.value);
                        }}
                        type="number"
                        className="form-control tabinput"
                        placeholder="Enter tax"
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-3">
                    <div className="adultsmaindiv">
                      <label>Maximum Adults</label>
                      <div className="adultsboxdiv">
                        <div
                          className="btn plusbox"
                          onClick={() => {
                            handleplusadults();
                          }}
                        >
                          +
                        </div>
                        <div className="adultinput">
                          <p>{maxadultsRef.current}</p>
                        </div>
                        <div
                          className="btn plusbox"
                          onClick={() => {
                            handleminusadults();
                          }}
                        >
                          -
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="adultsmaindiv">
                      <label>Maximum Childrens</label>
                      <div className="adultsboxdiv">
                        <div
                          className="btn plusbox"
                          onClick={() => {
                            handlepluschildrens();
                          }}
                        >
                          +
                        </div>
                        <div className="adultinput">
                          <p>{maxchildrensRef.current}</p>
                        </div>
                        <div
                          className="btn plusbox"
                          onClick={() => {
                            handleminuschildrens();
                          }}
                        >
                          -
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="adultsmaindiv">
                      <label>Maximum Rooms</label>
                      <div className="adultsboxdiv">
                        <div
                          className="btn plusbox"
                          onClick={() => {
                            handleplusrooms();
                          }}
                        >
                          +
                        </div>
                        <div className="adultinput">
                          <p>{maxroomsRef.current}</p>
                        </div>
                        <div
                          className="btn plusbox"
                          onClick={() => {
                            handleminusrooms();
                          }}
                        >
                          -
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label>Room Size (in sq. ft)</label>
                    <input
                      value={roomsize}
                      onChange={(t) => {
                        setroomsize(t.target.value);
                      }}
                      type="number"
                      className="form-control tabinput"
                      placeholder="Enter Room size"
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="plusminusdiv">
                    <div
                      className="roomsplusicondiv"
                      onClick={() => {
                        handleplusratesdiv();
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        className="roomplusicon"
                        style={{ color: "white" }}
                      />
                    </div>
                    <div
                      className="roomsplusicondiv"
                      style={{ marginLeft: 10, backgroundColor: "#dc3545" }}
                      onClick={() => {
                        handleminusratesdiv();
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faMinus}
                        className="roomplusicon"
                        style={{ color: "white" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  {roomsdescriptionRef.current?.map((item, index) => {
                    return (
                      <div className="roomsdescroom">
                        <div className="row ">
                          <div className="col-md-3">
                            <div className="">
                              <label>MRP Price</label>
                              <input
                                value={item?.price}
                                onChange={(t) => {
                                  handleinputchangeratesdess(
                                    index,
                                    "price",
                                    t.target.value
                                  );
                                }}
                                type="number"
                                className="form-control tabinput"
                                placeholder="Enter MRP Price"
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="">
                              <label>Offer Price</label>
                              <input
                                value={item?.offerprice}
                                onChange={(t) => {
                                  handleinputchangeratesdess(
                                    index,
                                    "offerprice",
                                    t.target.value
                                  );
                                }}
                                type="number"
                                className="form-control tabinput"
                                placeholder="Enter Offer Price"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label>Rate Title</label>
                            <input
                              value={item?.ratetitle}
                              onChange={(t) => {
                                handleinputchangeratesdess(
                                  index,
                                  "ratetitle",
                                  t.target.value
                                );
                              }}
                              type="text"
                              className="form-control tabinput"
                              placeholder="Enter Rate tab title"
                            />
                            
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-12">
                            <Editor
                              value={item?.ratedesc}
                              onChange={(t) => {
                                handleinputchangeratesdess(
                                  index,
                                  "ratedesc",
                                  t
                                );
                              }}
                              editorLoaded={editorLoaded}
                              
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="row mt-3">
                  <div className="col-md-12">
                    <div className="">
                      <label>Multiple Image</label>
                      <input
                        onChange={(t) => {
                          multipleimagesuploadfunc(t.target.files);
                        }}
                        multiple
                        type="file"
                        className="form-control tabinput"
                        placeholder="Enter Applicable tax"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label>Description</label>
                    <Editor
                      value={amenitiesdesc}
                      onChange={(t) => {
                        // handleinputchangeratesdess(index, "amenitiesdesc", t);
                        setamenitiesdesc(t)
                      }}
                      editorLoaded={editorLoaded}
                    />
                  </div>
                </div>

                {/* <div className="mt-3">
                    <label>Country</label>
                    <Select value={countryname} onChange={handlechange} options={options} />
                  </div> */}
                <div
                  className="webadmin-modalbtn"
                  onClick={() => {
                    submithandle();
                  }}
                >
                  <p>Submit</p>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/*Edit modal */}
          <Modal
            show={editshowmodal}
            onHide={() => seteditshowmodal(false)}
            size="lg"
            className="custom-width-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title style={{ fontSize: 19 }}>Edit Room</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="webadmin-modalmainbody">
                <div className="row">
                  <div className="col-md-5">
                    <div className="">
                      <label>Room Title</label>
                      <input
                        value={roomtitle}
                        onChange={(t) => {
                          setroomtitle(t.target.value);
                        }}
                        type="text"
                        className="form-control tabinput"
                        placeholder="Enter Title of room"
                      />
                    </div>
                  </div>
                  <div className="col-md-5">
                    <label>Select Hotel</label>
                    <Select
                      value={selectedhotel}
                      onChange={handlechange}
                      options={hotelsarr}
                    />
                  </div>
                  <div className="col-md-2">
                    <div className="">
                      <label>Tax(in %)</label>
                      <input
                        value={roomtax}
                        onChange={(t) => {
                          setroomtax(t.target.value);
                        }}
                        type="number"
                        className="form-control tabinput"
                        placeholder="Enter tax"
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-3">
                    <div className="adultsmaindiv">
                      <label>Maximum Adults</label>
                      <div className="adultsboxdiv">
                        <div
                          className="btn plusbox"
                          onClick={() => {
                            handleplusadults();
                          }}
                        >
                          +
                        </div>
                        <div className="adultinput">
                          <p>{maxadultsRef.current}</p>
                        </div>
                        <div
                          className="btn plusbox"
                          onClick={() => {
                            handleminusadults();
                          }}
                        >
                          -
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="adultsmaindiv">
                      <label>Maximum Childrens</label>
                      <div className="adultsboxdiv">
                        <div
                          className="btn plusbox"
                          onClick={() => {
                            handlepluschildrens();
                          }}
                        >
                          +
                        </div>
                        <div className="adultinput">
                          <p>{maxchildrensRef.current}</p>
                        </div>
                        <div
                          className="btn plusbox"
                          onClick={() => {
                            handleminuschildrens();
                          }}
                        >
                          -
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="adultsmaindiv">
                      <label>Maximum Rooms</label>
                      <div className="adultsboxdiv">
                        <div
                          className="btn plusbox"
                          onClick={() => {
                            handleplusrooms();
                          }}
                        >
                          +
                        </div>
                        <div className="adultinput">
                          <p>{maxroomsRef.current}</p>
                        </div>
                        <div
                          className="btn plusbox"
                          onClick={() => {
                            handleminusrooms();
                          }}
                        >
                          -
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label>Room Size (in sq. ft)</label>
                    <input
                      value={roomsize}
                      onChange={(t) => {
                        setroomsize(t.target.value);
                      }}
                      type="number"
                      className="form-control tabinput"
                      placeholder="Enter Room size"
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="plusminusdiv">
                    <div
                      className="roomsplusicondiv"
                      onClick={() => {
                        handleplusratesdiv();
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        className="roomplusicon"
                        style={{ color: "white" }}
                      />
                    </div>
                    <div
                      className="roomsplusicondiv"
                      style={{ marginLeft: 10, backgroundColor: "#dc3545" }}
                      onClick={() => {
                        handleminusratesdiv();
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faMinus}
                        className="roomplusicon"
                        style={{ color: "white" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  {roomsdescriptionRef.current?.map((item, index) => {
                    return (
                      <div className="roomsdescroom">
                        <div className="row ">
                          <div className="col-md-3">
                            <div className="">
                              <label>MRP Price</label>
                              <input
                                value={item?.price}
                                onChange={(t) => {
                                  handleinputchangeratesdess(
                                    index,
                                    "price",
                                    t.target.value
                                  );
                                }}
                                type="number"
                                className="form-control tabinput"
                                placeholder="Enter MRP Price"
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="">
                              <label>Offer Price</label>
                              <input
                                value={item?.offerprice}
                                onChange={(t) => {
                                  handleinputchangeratesdess(
                                    index,
                                    "offerprice",
                                    t.target.value
                                  );
                                }}
                                type="number"
                                className="form-control tabinput"
                                placeholder="Enter Offer Price"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label>Rate Title</label>
                            <input
                              value={item?.ratetitle}
                              onChange={(t) => {
                                handleinputchangeratesdess(
                                  index,
                                  "ratetitle",
                                  t.target.value
                                );
                              }}
                              type="text"
                              className="form-control tabinput"
                              placeholder="Enter Rate tab title"
                            />
                            
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-12">
                            <Editor
                              value={item?.ratedesc}
                              onChange={(t) => {
                                handleinputchangeratesdess(
                                  index,
                                  "ratedesc",
                                  t
                                );
                              }}
                              editorLoaded={editorLoaded}
                              
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="row mt-3">
                  <div className="col-md-12">
                    <div className="">
                      <label>Multiple Image</label>
                      <input
                        onChange={(t) => {
                          multipleimagesuploadfunc(t.target.files);
                        }}
                        multiple
                        type="file"
                        className="form-control tabinput"
                        placeholder="Upload Images"
                      />
                      {imagesarr.length > 0 ? (
                                <div className="updateroomimgdiv">
                                  {imagesarr?.map((itm, idx) => {
                                    if (typeof itm == "string") {
                                      return (
                                        <div
                                          className="updateroomimgbox"
                                          key={idx}
                                        >
                                          <img
                                            src={
                                              assetsUrl + "roomimages/" + itm
                                            }
                                          />
                                          <FontAwesomeIcon
                                            onClick={() => {
                                              removeimagefunc(idx);
                                            }}
                                            icon={faXmark}
                                            className="roomimagesicon"
                                            style={{ color: "red" }}
                                          />
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                              ) : null}
                    </div>
                  </div>
                  <div className="col-md-12 mt-3">
                    <label>Description</label>
                    <Editor
                      value={amenitiesdesc}
                      onChange={(t) => {
                        // handleinputchangeratesdess(index, "amenitiesdesc", t);
                        setamenitiesdesc(t)
                      }}
                      editorLoaded={editorLoaded}
                    />
                  </div>
                </div>

                {/* <div className="mt-3">
                    <label>Country</label>
                    <Select value={countryname} onChange={handlechange} options={options} />
                  </div> */}
                <div
                  className="webadmin-modalbtn"
                  onClick={() => {
                    submithandle1();
                  }}
                >
                  <p>Submit</p>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Rooms;
